import React, { useState, useCallback } from 'react';
import { getBezierPath, EdgeProps, useReactFlow } from 'reactflow';
import { EdgeData } from '../../types';

const CustomEdge: React.FC<EdgeProps<EdgeData>> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
}) => {
  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const [isHovered, setIsHovered] = useState(false);
  const { setEdges } = useReactFlow();

  const onEdgeClick = useCallback(
    (evt: React.MouseEvent<SVGGElement, MouseEvent>, id: string) => {
      evt.stopPropagation();
      setEdges((edges) => edges.filter((edge) => edge.id !== id));
    },
    [setEdges]
  );


  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      />
      <text>
        <textPath
          href={`#${id}`}
          style={{ fontSize: 12 }}
          startOffset="50%"
          textAnchor="middle"
        >
          {data?.label}
        </textPath>
      </text>
      {isHovered && (
        <g
          transform={`translate(${(sourceX + targetX) / 2}, ${
            (sourceY + targetY) / 2
          })`}
          onClick={(event) => onEdgeClick(event, id)}
          style={{ cursor: 'pointer' }}
        >
          <circle r="10" fill="red" />
          <text
            x="0"
            y="0"
            textAnchor="middle"
            alignmentBaseline="middle"
            fill="white"
            fontSize="12"
          >
            X
          </text>
        </g>
      )}
    </>
  );
};

export default CustomEdge;