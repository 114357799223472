import React from 'react';

interface LogoProps {
  onNavigate: (path: string) => void;
}

const Logo: React.FC<LogoProps> = ({ onNavigate }) => {
  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    onNavigate('/');
  };

  return (
    <div 
      className="flex items-center space-x-2 cursor-pointer" 
      onClick={handleClick}
    >
      <img src="/flow.png" alt="FlowGenius Logo" className="h-8 w-8" />
      <span className="text-2xl font-bold">Smart AI Flow</span>
    </div>
  );
};

export default Logo;