import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Check, ArrowRight } from 'lucide-react';

const colors = {
  primary: '#2980b9',
  secondary: '#8e44ad',
  accent: '#FFD700',
  textPrimary: '#2c3e50',
  textSecondary: '#ffffff',
  bgPrimary: '#f3f4f6',
  bgSecondary: '#1a202c',
};

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const Section = styled.section`
  padding: 6rem 2rem;
  background: linear-gradient(135deg, ${colors.bgPrimary}, ${colors.primary}20);
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const Title = styled.h2`
  font-size: 3.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 4rem;
  color: ${colors.textPrimary};
  animation: ${fadeIn} 1s ease-out;
`;

const BenefitsList = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2.5rem;
  list-style: none;
  padding: 0;
`;

interface BenefitItemProps {
  $index: number;
}

const BenefitItem = styled.li<BenefitItemProps>`
  background-color: ${colors.bgSecondary};
  padding: 2.5rem;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.1);
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  opacity: 0;
  transform: translateY(20px);
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${props => props.$index * 0.2}s;

  &:hover {
    transform: translateY(-10px) scale(1.03);
    box-shadow: 0 15px 30px rgba(0,0,0,0.2);
  }
`;

const IconWrapper = styled.div`
  background-color: ${colors.primary};
  border-radius: 50%;
  padding: 1rem;
  margin-bottom: 1.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;

  ${BenefitItem}:hover & {
    background-color: ${colors.accent};
    animation: ${pulse} 1s infinite;
  }
`;

const StyledCheck = styled(Check)`
  color: ${colors.textSecondary};
  transition: all 0.3s ease;

  ${BenefitItem}:hover & {
    color: ${colors.primary};
  }
`;

const BenefitTitle = styled.h3`
  color: ${colors.textSecondary};
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 600;
`;

const BenefitText = styled.p`
  color: ${colors.textSecondary}cc;
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
`;

const LearnMoreButton = styled.button`
  background-color: transparent;
  border: 2px solid ${colors.accent};
  color: ${colors.accent};
  padding: 0.5rem 1rem;
  border-radius: 25px;
  font-weight: 600;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    background-color: ${colors.accent};
    color: ${colors.bgSecondary};
  }

  svg {
    margin-left: 0.5rem;
    transition: transform 0.3s ease;
  }

  &:hover svg {
    transform: translateX(5px);
  }
`;

interface Benefit {
  title: string;
  description: string;
  details: string;
}

const Benefits: React.FC = () => {
  const [expandedBenefit, setExpandedBenefit] = useState<number | null>(null);

  const benefits: Benefit[] = [
    {
      title: "Save Time",
      description: "Automate repetitive tasks and focus on what matters most.",
      details: "Our AI-powered automation tools can save you up to 40% of your time on routine tasks, allowing you to concentrate on strategic decisions and creative problem-solving."
    },
    {
      title: "Reduce Errors",
      description: "Let AI handle complex decisions with precision.",
      details: "By leveraging machine learning algorithms, we can reduce human error by up to 90% in data-intensive processes, ensuring higher accuracy and reliability in your workflows."
    },
    {
      title: "Scale Effortlessly",
      description: "Easily adapt your workflows as your business grows.",
      details: "Our scalable solutions grow with your business, handling increased workloads without compromising performance. You can scale from hundreds to millions of operations seamlessly."
    },
    {
      title: "Innovate Faster",
      description: "Experiment with new processes without technical barriers.",
      details: "With our no-code platform, you can prototype and implement new ideas 5x faster than traditional development methods, accelerating your innovation cycle."
    }
  ];

  return (
    <Section id="benefits">
      <Container>
        <Title>Unlock Your Potential</Title>
        <BenefitsList>
          {benefits.map((benefit, index) => (
            <BenefitItem key={index} $index={index}>
              <IconWrapper>
                <StyledCheck size={28} />
              </IconWrapper>
              <BenefitTitle>{benefit.title}</BenefitTitle>
              <BenefitText>
                {expandedBenefit === index ? benefit.details : benefit.description}
              </BenefitText>
              <LearnMoreButton onClick={() => setExpandedBenefit(expandedBenefit === index ? null : index)}>
                {expandedBenefit === index ? 'Show Less' : 'Learn More'}
                <ArrowRight size={16} />
              </LearnMoreButton>
            </BenefitItem>
          ))}
        </BenefitsList>
      </Container>
    </Section>
  );
};

export default Benefits;