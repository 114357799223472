import React, { useState, useCallback, useRef } from 'react';
import ReactFlow, { 
  Node, 
  Edge, 
  Connection, 
  addEdge, 
  useNodesState, 
  useEdgesState,
  ReactFlowProvider,
  ReactFlowInstance,
  Controls,
  reconnectEdge,
  HandleType,
  Background,
  MiniMap,
  BackgroundVariant
} from 'reactflow';
import 'reactflow/dist/style.css';

import LeftPanel from '../components/Panels/LeftPanel';
import RightPanel from '../components/Panels/RightPanel';
import WorkflowControls from '../components/WorkflowControls';
import { StartNode } from '../components/NodeTypes/StartNode';
import { LLMNode } from '../components/NodeTypes/LLMNode';
import { ToolNode } from '../components/NodeTypes/ToolNode';
import CustomEdge from '../components/Edges/CustomEdge';
import { withDeleteButton } from '../components/withDeleteButton';
import { NodeData, EdgeData, CustomNode } from '../types';

const nodeTypes = {
  start: withDeleteButton(StartNode),
  llm: withDeleteButton(LLMNode),
  tool: withDeleteButton(ToolNode),
};

const edgeTypes = {
  custom: CustomEdge,
};

let id = 0;
const getId = () => `dndnode_${id++}`;

const FlowPage: React.FC = () => {
  const reactFlowWrapper = useRef<HTMLDivElement>(null);
  const [nodes, setNodes, onNodesChange] = useNodesState<NodeData>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<EdgeData>([]);
  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance | null>(null);
  const [selectedNode, setSelectedNode] = useState<CustomNode | null>(null);
  const edgeReconnectSuccessful = useRef(true);

  const onConnect = useCallback((params: Connection | Edge) => {
    setEdges((eds) => addEdge({ ...params, type: 'custom' }, eds));
  }, [setEdges]);

  const onNodeClick = useCallback((event: React.MouseEvent, node: Node) => {
    setSelectedNode(node);
  }, []);

  const updateNode = useCallback((updatedNode: Node) => {
    setNodes((nds) => nds.map((node) => (node.id === updatedNode.id ? updatedNode : node)));
    setSelectedNode(updatedNode);
  }, [setNodes]);

  const onNodesDelete = useCallback((deletedNodes: Node[]) => {
    if (selectedNode && deletedNodes.find(node => node.id === selectedNode.id)) {
      setSelectedNode(null);
    }
  }, [selectedNode]);

  const onDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      if (!reactFlowWrapper.current || !reactFlowInstance) return;

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData('application/reactflow');

      if (typeof type === 'undefined' || !type) {
        return;
      }

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });

      const newNode: Node<NodeData> = {
        id: getId(),
        type,
        position,
        data: type === 'start' 
          ? { label: `${type} node`, config: {}, state: {} }
          : { label: `${type} node` },
      };

      setNodes((nds) => nds.concat(newNode));
    },
    [reactFlowInstance, setNodes]
  );
  const onReconnectStart = useCallback(() => {
    edgeReconnectSuccessful.current = false;
  }, []);

  const onReconnect = useCallback((oldEdge: Edge, newConnection: Connection) => {
    edgeReconnectSuccessful.current = true;
    setEdges((els) => reconnectEdge(oldEdge, newConnection, els));
  }, [setEdges]);

  const onReconnectEnd = useCallback((event: MouseEvent | TouchEvent, edge: Edge, handleType: HandleType) => {
    if (!edgeReconnectSuccessful.current) {
      setEdges((eds) => eds.filter((e) => e.id !== edge.id));
    }
    edgeReconnectSuccessful.current = true;
  }, [setEdges]);

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <ReactFlowProvider>
        <LeftPanel />
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <WorkflowControls nodes={nodes} edges={edges} />
          <div ref={reactFlowWrapper} style={{ flexGrow: 1 }}>
            <ReactFlow
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              onInit={setReactFlowInstance}
              onDrop={onDrop}
              onDragOver={onDragOver}
              onNodeClick={onNodeClick}
              onNodesDelete={onNodesDelete}
              nodeTypes={nodeTypes}
              edgeTypes={edgeTypes}
              onReconnect={onReconnect}
              onReconnectStart={onReconnectStart}
              onReconnectEnd={onReconnectEnd}
              
              
              snapToGrid
              fitView
            >
              <Background
        id="1"
        gap={10}
        color="#f1f1f1"
        variant={BackgroundVariant.Dots}
      />
 
    
              <Controls />
              <MiniMap nodeStrokeWidth={3} />
            </ReactFlow>
          </div>
        </div>
        <RightPanel
          selectedNode={selectedNode}
          updateNode={updateNode}
        />
      </ReactFlowProvider>
    </div>
  );
};

export default FlowPage;