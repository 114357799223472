import React from 'react';
import { Handle, Position, NodeProps, } from 'reactflow';
import { NodeData } from '../../types';

export const LLMNode: React.FC<NodeProps<NodeData>> = ({ data }) => {
  return (
    <>
    
        
      
    <div className="px-4 py-2 shadow-md rounded-md bg-white border-2 border-stone-400">
      
      <div className="text-lg font-bold">LLM: {data.label}</div>
      <div className="text-gray-500">Model: {data.model}</div>
      
      
    </div>
    <Handle type="source" position={Position.Bottom} id='1'  />
    <Handle type="target" position={Position.Top} id='2'/>
    <Handle type="target" position={Position.Left} id='3'/>
    </>
  );
};

export const llmNodeProperties = [
  { id: 'label', label: 'Label', type: 'text' as const },
  { id: 'model', label: 'Model', type: 'select' as const, options: ['GPT-3', 'GPT-4', 'Claude'] },
  { id: 'prompt', label:'System Prompt', type: 'text' as const }
];