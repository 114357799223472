import React from 'react';
import { Handle, Position, NodeProps } from 'reactflow';
import { NodeData } from '../../types';

export interface StartNodeData extends NodeData {
  config: Record<string, string>;
  state: Record<string, string>;
}

export const StartNode: React.FC<NodeProps<StartNodeData>> = ({ data }) => {
  return (
    <div style={{ padding: '10px', border: '1px solid #ddd', borderRadius: '5px', background: 'white' }}>
      <Handle type="source" position={Position.Bottom} />
      <strong>Start: {data.label}</strong>
      <div>Config: {Object.keys(data.config).length} items</div>
      <div>State: {Object.keys(data.state).length} items</div>
    </div>
  );
};

export const startNodeProperties = [
  { id: 'label', label: 'Label', type: 'text' as const },
  { id: 'config', label: 'Config', type: 'section' as const },
  { id: 'state', label: 'State', type: 'section' as const }
];