import React, { useState } from 'react';
import { Node, Edge } from 'reactflow';
import { NodeData, EdgeData } from '../types';

interface WorkflowControlsProps {
  nodes: Node<NodeData>[];
  edges: Edge<EdgeData>[];
}

const WorkflowControls: React.FC<WorkflowControlsProps> = ({ nodes, edges }) => {
  const [userMessage, setUserMessage] = useState('');

  const handleRunWorkflow = () => {
    const graphData = {
      nodes: nodes.map(node => ({
        id: node.id,
        type: node.type,
        position: node.position,
        data: node.data
      })),
      edges: edges.map(edge => ({
        id: edge.id,
        source: edge.source,
        target: edge.target,
        type: edge.type,
        data: edge.data
      })),
      userMessage
    };

    console.log('Workflow Graph Data:', JSON.stringify(graphData, null, 2));
  };

  return (
    <div style={{ padding: '15px', borderBottom: '1px solid #eee' }}>
      <input
        type="text"
        value={userMessage}
        onChange={(e) => setUserMessage(e.target.value)}
        placeholder="Enter your message"
        style={{ marginRight: '10px' }}
      />
      <button onClick={handleRunWorkflow}>Run Workflow</button>
    </div>
  );
};

export default WorkflowControls;