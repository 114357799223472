import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Check } from 'lucide-react';

const colors = {
  primary: '#2980b9',
  secondary: '#8e44ad',
  accent: '#FFD700',
  textPrimary: '#2c3e50',
  textSecondary: '#ffffff',
  bgPrimary: '#f3f4f6',
  bgSecondary: '#1a202c',
};

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
`;

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const FeaturesSection = styled.section`
  padding: 6rem 2rem;
  background: linear-gradient(135deg, ${colors.bgPrimary}, ${colors.primary}20);
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const Title = styled.h2`
  font-size: 3.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 4rem;
  color: ${colors.textPrimary};
  animation: ${fadeIn} 1s ease-out, ${float} 3s ease-in-out infinite;
`;

const FeatureGrid = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2.5rem;
  list-style: none;
  padding: 0;
`;

interface FeatureCardProps {
  $index: number;
}

const FeatureCard = styled.li<FeatureCardProps>`
  background-color: ${colors.bgSecondary};
  padding: 2.5rem;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
  animation: ${fadeIn} 0.6s ease-out forwards;
  animation-delay: ${props => props.$index * 0.1}s;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0,0,0,0.2);
  }
`;

const IconWrapper = styled.div`
  background-color: ${colors.primary};
  border-radius: 50%;
  padding: 1rem;
  margin-bottom: 1.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;

  ${FeatureCard}:hover & {
    background-color: ${colors.accent};
    animation: ${pulse} 1s infinite;
  }
`;

const StyledCheck = styled(Check)`
  color: ${colors.textSecondary};
  transition: all 0.3s ease;

  ${FeatureCard}:hover & {
    color: ${colors.primary};
  }
`;

const FeatureTitle = styled.h3`
  color: ${colors.textSecondary};
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 600;
`;

const FeatureDescription = styled.p`
  color: ${colors.textSecondary}cc;
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
`;

const ProgressBarWrapper = styled.div`
  overflow: hidden;
  height: 0.25rem;
  background-color: ${colors.bgPrimary}40;
  border-radius: 0.25rem;
`;

const ProgressBar = styled.div`
  width: 0;
  height: 100%;
  background-color: ${colors.accent};
  transition: width 1s ease-out;

  ${FeatureCard}:hover & {
    width: 100%;
  }
`;

const Features: React.FC = () => {
  const features = [
    "Intuitive Drag-and-Drop Interface",
    "AI Agent Marketplace",
    "Real-Time Collaboration",
    "Powerful Analytics",
    "Seamless Integration"
  ];

  return (
    <FeaturesSection id="features">
      <Container>
        <Title>Key Features of Smart AI Flow</Title>
        <FeatureGrid>
          {features.map((feature, index) => (
            <FeatureCard key={index} $index={index}>
              <IconWrapper>
                <StyledCheck size={28} />
              </IconWrapper>
              <FeatureTitle>{feature}</FeatureTitle>
              <FeatureDescription>
                Experience the power of {feature.toLowerCase()} with Smart AI Flow.
              </FeatureDescription>
              <ProgressBarWrapper>
                <ProgressBar />
              </ProgressBarWrapper>
            </FeatureCard>
          ))}
        </FeatureGrid>
      </Container>
    </FeaturesSection>
  );
};

export default Features;