import React from 'react';
import Header from '../components/landingpage/Header';
import Footer from '../components/landingpage/Footer';

interface MainLayoutProps {
  children: React.ReactNode;
  onNavigate: (path: string) => void;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children, onNavigate }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <Header onNavigate={onNavigate} />
      <main className="flex-grow pt-16">
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default MainLayout;