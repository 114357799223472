import React from 'react';
import styled, { keyframes } from 'styled-components';

const colors = {
  primary: '#2980b9',
  secondary: '#8e44ad',
  accent: '#FFD700',
  textPrimary: '#2c3e50',
  textSecondary: '#ffffff',
  bgPrimary: '#f3f4f6',
  bgSecondary: '#1a202c',
};

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideUp = keyframes`
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

const Section = styled.section`
  padding: 6rem 2rem;
  background: linear-gradient(135deg, ${colors.bgPrimary}, ${colors.primary}20);
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const Title = styled.h2`
  font-size: 3.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 4rem;
  color: ${colors.textPrimary};
  animation: ${fadeIn} 1s ease-out;
`;

const StepGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 2.5rem;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

interface StepCardProps {
  $delay: number;
}

const StepCard = styled.div<StepCardProps>`
  text-align: center;
  animation: ${slideUp} 0.6s ease-out forwards;
  animation-delay: ${props => props.$delay}s;
  opacity: 0;
  background-color: ${colors.bgSecondary};
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.1);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0,0,0,0.2);
  }
`;

const StepNumber = styled.div`
  background-color: ${colors.primary};
  color: ${colors.textSecondary};
  border-radius: 50%;
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1.5rem;
  font-weight: bold;
  font-size: 1.5rem;
  transition: all 0.3s ease;

  ${StepCard}:hover & {
    background-color: ${colors.accent};
    color: ${colors.textPrimary};
    transform: scale(1.1);
  }
`;

const StepTitle = styled.h3`
  font-weight: bold;
  margin-bottom: 1rem;
  color: ${colors.textSecondary};
  font-size: 1.5rem;
`;

const StepDescription = styled.p`
  color: ${colors.textSecondary}cc;
  font-size: 1.1rem;
  line-height: 1.6;
`;

const HowItWorks: React.FC = () => {
  const steps = [
    { title: "Design", description: "Drag and drop AI agents and actions onto the canvas." },
    { title: "Connect", description: "Link actions and set conditions for your workflow." },
    { title: "Deploy", description: "Activate your flow with a single click." },
    { title: "Monitor", description: "Track performance and optimize in real-time." }
  ];

  return (
    <Section id="how-it-works">
      <Container>
        <Title>How It Works</Title>
        <StepGrid>
          {steps.map((step, index) => (
            <StepCard key={index} $delay={0.1 * index}>
              <StepNumber>{index + 1}</StepNumber>
              <StepTitle>{step.title}</StepTitle>
              <StepDescription>{step.description}</StepDescription>
            </StepCard>
          ))}
        </StepGrid>
      </Container>
    </Section>
  );
};

export default HowItWorks;