import React, { useEffect, useRef } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { ArrowRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideUp = keyframes`
  from { transform: translateY(50px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0px); }
`;

const HeroSection = styled.section`
  background: linear-gradient(135deg, #3498db, #8e44ad);
  color: white;
  padding: 8rem 0;
  position: relative;
  overflow: hidden;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 1;
`;

const Title = styled.h1`
  font-size: 3.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  opacity: 0;
  animation: ${css`${fadeIn} 1s forwards, ${slideUp} 1s forwards`};
`;

const Subtitle = styled.p`
  font-size: 1.5rem;
  margin-bottom: 2rem;
  opacity: 0;
  animation: ${css`${fadeIn} 1s 0.5s forwards, ${slideUp} 1s 0.5s forwards`};
`;

const Button = styled.button`
  background-color: white;
  color: #3498db;
  font-weight: bold;
  padding: 0.75rem 1.5rem;
  border-radius: 9999px;
  display: inline-flex;
  align-items: center;
  transition: all 0.3s;
  opacity: 0;
  animation: ${css`${fadeIn} 1s 1s forwards, ${slideUp} 1s 1s forwards, ${pulse} 2s 2s infinite`};

  &:hover {
    background-color: #3498db;
    color: white;
    transform: translateY(-3px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;

const StyledArrowRight = styled(ArrowRight)`
  margin-left: 0.5rem;
  transition: transform 0.3s;

  ${Button}:hover & {
    transform: translateX(5px);
  }
`;

const Particles = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

const Hero: React.FC = () => {
  const navigate = useNavigate();
  const particlesRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const createParticle = () => {
      if (particlesRef.current) {
        const particle = document.createElement('div');
        const size = Math.random() * 10 + 5;
        const left = Math.random() * 100;
        const animationDuration = Math.random() * 2 + 2;
        const delay = Math.random() * 2;

        particle.style.cssText = `
          position: absolute;
          width: ${size}px;
          height: ${size}px;
          background-color: rgba(255, 255, 255, 0.5);
          border-radius: 50%;
          left: ${left}%;
          top: -50px;
          animation: ${float.getName()} ${animationDuration}s ease-in-out infinite ${delay}s;
        `;

        particlesRef.current.appendChild(particle);

        setTimeout(() => {
          if (particlesRef.current && particlesRef.current.contains(particle)) {
            particlesRef.current.removeChild(particle);
          }
        }, animationDuration * 1000 + delay * 1000 + 1000);
      }
    };

    const interval = setInterval(createParticle, 200);

    return () => clearInterval(interval);
  }, []);

  const handleGetStarted = () => {
    navigate('/flow');
  };

  return (
    <HeroSection>
      <Container>
        <Title>AI-Powered Flow Automation Made Easy</Title>
        <Subtitle>Unleash the Power of AI in Your Workflows</Subtitle>
        <Button onClick={handleGetStarted}>
          Get Started <StyledArrowRight />
        </Button>
      </Container>
      <Particles ref={particlesRef} />
    </HeroSection>
  );
};

export default Hero;