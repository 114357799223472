import React from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import MainLayout from './layouts/MainLayout';
import LandingPage from './pages/LandingPage';
import FlowPage from './pages/FlowPage';

const App: React.FC = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

const AppContent: React.FC = () => {
  const navigate = useNavigate();

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  return (
    <MainLayout onNavigate={handleNavigate}>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/flow" element={<FlowPage />} />
      </Routes>
    </MainLayout>
  );
};

export default App;