import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { ChevronRight, Zap } from 'lucide-react';

const colors = {
  primary: '#2980b9',
  secondary: '#8e44ad',
  accent: '#FFD700',
  textPrimary: '#2c3e50',
  textSecondary: '#ffffff',
  bgPrimary: '#f3f4f6',
  bgSecondary: '#1a202c',
};

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const Section = styled.section`
  padding: 6rem 2rem;
  background: linear-gradient(135deg, ${colors.bgPrimary}, ${colors.primary}20);
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const Title = styled.h2`
  font-size: 3.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 4rem;
  color: ${colors.textPrimary};
  animation: ${fadeIn} 1s ease-out;
`;

const UseCaseGrid = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  list-style: none;
  padding: 0;
`;

interface UseCaseItemProps {
  $index: number;
}

const UseCaseItem = styled.li<UseCaseItemProps>`
  background-color: ${colors.bgSecondary};
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
  opacity: 0;
  transform: translateY(20px);
  animation: ${fadeIn} 0.6s ease-out forwards;
  animation-delay: ${props => props.$index * 0.1}s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0,0,0,0.2);
    background-color: ${colors.primary};
  }
`;

const UseCaseText = styled.span`
  font-size: 1.2rem;
  font-weight: 600;
  color: ${colors.textSecondary};
  transition: color 0.3s ease;
`;

const StyledChevron = styled(ChevronRight)`
  color: ${colors.accent};
  transition: all 0.3s ease;

  ${UseCaseItem}:hover & {
    transform: translateX(5px);
  }
`;

const MoreText = styled.p`
  text-align: center;
  margin-top: 3rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: ${colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  animation: ${pulse} 2s infinite;
`;

const StyledZap = styled(Zap)`
  color: ${colors.accent};
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: ${colors.bgPrimary};
  padding: 2rem;
  border-radius: 15px;
  max-width: 500px;
  width: 90%;
`;

const ModalTitle = styled.h3`
  font-size: 2rem;
  font-weight: 700;
  color: ${colors.textPrimary};
  margin-bottom: 1rem;
`;

const ModalText = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  color: ${colors.textPrimary};
`;

const CloseButton = styled.button`
  background-color: ${colors.primary};
  color: ${colors.textSecondary};
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-weight: 600;
  margin-top: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${colors.secondary};
  }
`;

interface UseCase {
  title: string;
  description: string;
}

const UseCases: React.FC = () => {
  const [selectedUseCase, setSelectedUseCase] = useState<UseCase | null>(null);

  const useCases: UseCase[] = [
    {
      title: "Customer Service Automation",
      description: "Streamline your customer support with AI-powered chatbots and automated ticket routing, reducing response times by up to 80% and improving customer satisfaction."
    },
    {
      title: "Data Analysis and Reporting",
      description: "Leverage machine learning algorithms to analyze vast amounts of data and generate insightful reports, helping you make data-driven decisions faster and more accurately."
    },
    {
      title: "Content Generation and Curation",
      description: "Automate content creation and curation processes, from writing product descriptions to curating relevant news articles, saving time and ensuring consistent quality."
    },
    {
      title: "Lead Qualification and Nurturing",
      description: "Use AI to score and qualify leads, and automate personalized nurturing campaigns, increasing conversion rates and optimizing your sales funnel."
    },
    {
      title: "Inventory Management",
      description: "Implement smart inventory management systems that predict demand, optimize stock levels, and automate reordering processes, reducing costs and preventing stockouts."
    }
  ];

  return (
    <Section id="use-cases">
      <Container>
        <Title>Versatile Use Cases</Title>
        <UseCaseGrid>
          {useCases.map((useCase, index) => (
            <UseCaseItem 
              key={index} 
              $index={index}
              onClick={() => setSelectedUseCase(useCase)}
            >
              <UseCaseText>{useCase.title}</UseCaseText>
              <StyledChevron size={20} />
            </UseCaseItem>
          ))}
        </UseCaseGrid>
        <MoreText>
          And much more! <StyledZap size={24} />
        </MoreText>
      </Container>
      {selectedUseCase && (
        <Modal onClick={() => setSelectedUseCase(null)}>
          <ModalContent onClick={e => e.stopPropagation()}>
            <ModalTitle>{selectedUseCase.title}</ModalTitle>
            <ModalText>{selectedUseCase.description}</ModalText>
            <CloseButton onClick={() => setSelectedUseCase(null)}>Close</CloseButton>
          </ModalContent>
        </Modal>
      )}
    </Section>
  );
};

export default UseCases;