import React, { useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import Logo from './Logo';

interface HeaderProps {
  onNavigate: (path: string) => void;
}

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideDown = keyframes`
  from { transform: translateY(-20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

const HeaderContainer = styled.header`
  background: linear-gradient(135deg, #3498db, #8e44ad);
  color: white;
  padding: 1rem 2rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  margin: 0 auto;
`;

const LogoContainer = styled.div`
  flex-shrink: 0;
`;

const MenuContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const MenuItems = styled.ul<{ $isOpen: boolean }>`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  @media (max-width: 768px) {
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: linear-gradient(135deg, #3498db, #8e44ad);
    padding: 1rem;
    display: ${props => (props.$isOpen ? 'flex' : 'none')};
    animation: ${props => props.$isOpen ? css`${slideDown} 0.3s ease-out` : 'none'};
  }
`;

const MenuItem = styled.li`
  margin: 0 0 0 1.5rem;

  @media (max-width: 768px) {
    margin: 0.5rem 0;
  }
`;

const MenuLink = styled.a`
  color: white;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;

  &:hover {
    color: #FFD700;
  }
`;

const HamburgerButton = styled.button`
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
    margin-left: 1rem;
  }
`;

const AnimatedLogoWrapper = styled.div`
  animation: ${fadeIn} 1s ease-out;
`;

const Header: React.FC<HeaderProps> = ({ onNavigate }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleNavigation = (path: string) => {
    onNavigate(path);
    setIsMenuOpen(false);
  };

  return (
    <HeaderContainer>
      <Nav>
        <LogoContainer>
          <AnimatedLogoWrapper>
            <Logo onNavigate={onNavigate} />
          </AnimatedLogoWrapper>
        </LogoContainer>
        <MenuContainer>
          <MenuItems $isOpen={isMenuOpen}>
            <MenuItem><MenuLink href="#features" onClick={() => setIsMenuOpen(false)}>Features</MenuLink></MenuItem>
            <MenuItem><MenuLink href="#how-it-works" onClick={() => setIsMenuOpen(false)}>How It Works</MenuLink></MenuItem>
            <MenuItem><MenuLink href="#benefits" onClick={() => setIsMenuOpen(false)}>Benefits</MenuLink></MenuItem>
            <MenuItem><MenuLink href="#use-cases" onClick={() => setIsMenuOpen(false)}>Use Cases</MenuLink></MenuItem>
            <MenuItem><MenuLink href="/flow" onClick={(e) => { e.preventDefault(); handleNavigation('/flow'); }}>Flow</MenuLink></MenuItem>
          </MenuItems>
          <HamburgerButton onClick={toggleMenu}>
            {isMenuOpen ? '✕' : '☰'}
          </HamburgerButton>
        </MenuContainer>
      </Nav>
    </HeaderContainer>
  );
};

export default Header;