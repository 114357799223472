import React from 'react';
import Hero from '../components/landingpage/Hero';
import Features from '../components/landingpage/Features';
import HowItWorks from '../components/landingpage/HowItWorks';
import Benefits from '../components/landingpage/Benefits';
import UseCases from '../components/landingpage/UseCases';

const LandingPage: React.FC = () => {
  return (
    <>
      <Hero />
      <Features />
      <HowItWorks />
      <Benefits />
      <UseCases />
    </>
  );
};

export default LandingPage;
