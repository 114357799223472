import React from 'react';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
`;

const float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-5px); }
  100% { transform: translateY(0px); }
`;

const LeftPanelContainer = styled.aside`
  --color-primary: #2980b9;
  --color-secondary: #8e44ad;
  --color-accent: #FFD700;
  --color-text-primary: #2c3e50;
  --color-text-secondary: #ffffff;
  --color-bg-primary: #f3f4f6;
  --color-bg-secondary: #1a202c;

  padding: 2rem;
  border-right: 1px solid var(--color-secondary);
  width: 250px;
  background-color: var(--color-bg-secondary);
  color: var(--color-text-secondary);
  height: 100vh;
  overflow-y: auto;
  animation: ${fadeIn} 0.5s ease-out;
`;

const Description = styled.div`
  margin-bottom: 2rem;
  font-size: 0.9rem;
  line-height: 1.5;
  color: var(--color-text-secondary);
`;

const DraggableNode = styled.div`
  border: 2px solid var(--color-primary);
  padding: 1rem;
  text-align: center;
  margin-bottom: 1rem;
  background-color: var(--color-bg-primary);
  color: var(--color-text-primary);
  border-radius: 8px;
  cursor: move;
  transition: all 0.3s ease;
  animation: ${float} 3s ease-in-out infinite;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
    background-color: var(--color-primary);
    color: var(--color-text-secondary);
  }

  &.start {
    border-color: var(--color-primary);
  }

  &.llm {
    border-color: var(--color-secondary);
  }

  &.tool {
    border-color: var(--color-accent);
  }
`;

const LeftPanel: React.FC = () => {
  const onDragStart = (event: React.DragEvent<HTMLDivElement>, nodeType: string) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  };

  return (
    <LeftPanelContainer>
      <Description>You can drag these nodes to the pane on the right.</Description>
      <DraggableNode
        className="dndnode start"
        onDragStart={(event) => onDragStart(event, 'start')}
        draggable
      >
        Start Node
      </DraggableNode>
      <DraggableNode
        className="dndnode llm"
        onDragStart={(event) => onDragStart(event, 'llm')}
        draggable
      >
        LLM Node
      </DraggableNode>
      <DraggableNode
        className="dndnode tool"
        onDragStart={(event) => onDragStart(event, 'tool')}
        draggable
      >
        Tool Node
      </DraggableNode>
    </LeftPanelContainer>
  );
};

export default LeftPanel;