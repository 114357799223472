import React from 'react';
import { NodeProps, useReactFlow } from 'reactflow';
import { NodeData } from '../types';

export function withDeleteButton<T extends NodeData>(WrappedComponent: React.ComponentType<NodeProps<T>>) {
  return (props: NodeProps<T>) => {
    const { setNodes } = useReactFlow();

    const onDelete = () => {
      setNodes((nodes) => nodes.filter((node) => node.id !== props.id));
    };

    return (
      <div style={{ position: 'relative' }}>
        <WrappedComponent {...props} />
        <button
          style={{
            position: 'absolute',
            top: '-10px',
            right: '-10px',
            background: 'red',
            color: 'white',
            border: 'none',
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            cursor: 'pointer',
          }}
          onClick={onDelete}
        >
          X
        </button>
      </div>
    );
  };
}