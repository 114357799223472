import React from 'react';
import { Handle, Position, NodeProps } from 'reactflow';
import { NodeData } from '../../types';

export const ToolNode: React.FC<NodeProps<NodeData>> = ({ data }) => {
  return (
    <div style={{ padding: '10px', border: '1px solid #ddd', borderRadius: '5px', background: 'white' }}>
      <Handle type="target" position={Position.Top} />
      <strong>Tool: {data.label}</strong>
      <div>Type: {data.toolType}</div>
      <Handle type="source" position={Position.Bottom} />
    </div>
  );
};

export const toolNodeProperties = [
  { id: 'label', label: 'Label', type: 'text' as const },
  { id: 'toolType', label: 'Tool Type', type: 'select' as const, options: ['Search', 'Calculator', 'API Call'] }
];