import React, { useState, useEffect } from 'react';
import { Node } from 'reactflow';
import styled, { keyframes } from 'styled-components';
import { startNodeProperties, StartNodeData } from '../NodeTypes/StartNode';
import { llmNodeProperties } from '../NodeTypes/LLMNode';
import { toolNodeProperties } from '../NodeTypes/ToolNode';
import { NodeData } from '../../types';
import { ArrowRightCircle, ArrowLeftCircle } from 'lucide-react';
// Define keyframe animations
const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
`;
const PropertyFieldWrapper = styled.div`
  position: relative;
  overflow: hidden;
  margin-bottom: 1rem;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: var(--color-accent);
    transition: width 0.3s ease-out;
  }

  &:hover::after {
    width: 100%;
  }
`;

  

// Styled components
const PanelContainer = styled.div<{ isPanelVisible: boolean }>`
  --color-primary: #2980b9;
  --color-secondary: #8e44ad;
  --color-accent: #FFD700;
  --color-text-primary: #2c3e50;
  --color-text-secondary: #ffffff;
  --color-bg-primary: #f3f4f6;
  --color-bg-secondary: #1a202c;

  background-color: var(--color-bg-secondary);
  padding: 2rem;
  border-radius: 15px 0 0 15px;
  box-shadow: -5px 0 15px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
  position: fixed;
  right: ${props => props.isPanelVisible ? '0' : '-600px'};
  top: 60px;
  bottom: 0;
  width: 600px;
  overflow-y: auto;

  @media (max-width: 768px) {
    padding: 1.5rem;
    width: 100%;
    right: ${props => props.isPanelVisible ? '0' : '-100%'};
  }
`;

const PanelTitle = styled.h3`
  font-size: 1.8rem;
  color: var(--color-text-secondary);
  margin-bottom: 1.5rem;
  animation: ${fadeIn} 0.5s ease-out;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const PropertiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;



const Label = styled.label`
  display: block;
  color: var(--color-text-secondary);
  margin-bottom: 0.5rem;
  font-weight: 600;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: none;
  background-color: color-mix(in srgb, var(--color-bg-primary) 10%, transparent);
  color: var(--color-text-secondary);
  border-radius: 5px;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--color-accent);
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 0.75rem;
  border: none;
  background-color: color-mix(in srgb, var(--color-bg-primary) 10%, transparent);
  color: var(--color-text-secondary);
  border-radius: 5px;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--color-accent);
  }
`;

const DynamicSectionContainer = styled.div`
  background-color: color-mix(in srgb, var(--color-bg-primary) 5%, transparent);
  padding: 1.5rem;
  border-radius: 10px;
  animation: ${fadeIn} 0.5s ease-out;
`;

const SectionTitle = styled.h4`
  color: var(--color-text-secondary);
  font-size: 1.2rem;
  margin-bottom: 1rem;
`;

const DynamicInputGroup = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0.5rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const DynamicInput = styled.input`
  flex: 1;
  padding: 0.5rem;
  border: none;
  background-color: color-mix(in srgb, var(--color-bg-primary) 15%, transparent);
  color: var(--color-text-secondary);
  border-radius: 5px;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    filter: brightness(1.1);
  }
`;

const RemoveButton = styled(Button)`
  background-color: var(--color-secondary);
  color: var(--color-text-secondary);
`;

const AddButton = styled(Button)`
  background-color: var(--color-primary);
  color: var(--color-text-secondary);
`;

const IconWrapper = styled.div<{ isPanelVisible: boolean }>`
  position: fixed;
  top: 70px; // Adjust this value to position it just below the header
  right: ${props => props.isPanelVisible ? '600px' : '0'};
  background-color: var(--color-primary);
  border-radius: 50% 0 0 50%;
  padding: 0.5rem 0.5rem 0.5rem 0.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  cursor: pointer;
  background-color: #7f52b4;
  &:hover {
    background-color: #5379ca;;
  }

  svg {
    color: var(--color-text-secondary);
    transition: all 0.3s ease;
  }

  &:hover svg {
    color: var(--color-primary);
  }

  @media (max-width: 768px) {
    right: ${props => props.isPanelVisible ? 'calc(100% - 40px)' : '0'};
  }
`;

// Component interfaces
interface RightPanelProps {
    selectedNode: Node<NodeData> | null;
    updateNode: (updatedNode: Node<NodeData>) => void;
  }
  

  interface PropertyFieldType {
    id: string;
    label: string;
    type: 'text' | 'select' | 'section';
    options?: string[];
  }

// DynamicSection component
const DynamicSection: React.FC<{
    sectionName: string;
    data: Record<string, any>;
    onUpdate: (newData: Record<string, any>) => void;
  }> = ({ sectionName, data, onUpdate }) => {
    const [newKey, setNewKey] = useState('');
    const [newValue, setNewValue] = useState('');
  
    const handleAdd = () => {
      if (newKey && newValue) {
        onUpdate({ ...data, [newKey]: newValue });
        setNewKey('');
        setNewValue('');
      }
    };
  
    const handleRemove = (key: string) => {
      const newData = { ...data };
      delete newData[key];
      onUpdate(newData);
    };
  
    const handleChange = (key: string, value: string) => {
      onUpdate({ ...data, [key]: value });
    };
  
    const renderNestedObject = (obj: Record<string, any>, prefix = '') => {
      return Object.entries(obj).map(([key, value]) => {
        const fullKey = prefix ? `${prefix}.${key}` : key;
        if (typeof value === 'object' && value !== null) {
          return (
            <div key={fullKey}>
              <h5>{key}</h5>
              {renderNestedObject(value, fullKey)}
            </div>
          );
        }
        return (
          <DynamicInputGroup key={fullKey}>
            <DynamicInput type="text" value={key} readOnly />
            <DynamicInput
              type="text"
              value={value}
              onChange={(e) => handleChange(fullKey, e.target.value)}
            />
            <RemoveButton onClick={() => handleRemove(fullKey)}>Remove</RemoveButton>
          </DynamicInputGroup>
        );
      });
    };
  
    return (
      <DynamicSectionContainer>
        <SectionTitle>{sectionName}</SectionTitle>
        {renderNestedObject(data)}
        <DynamicInputGroup>
          <DynamicInput
            type="text"
            placeholder="New key"
            value={newKey}
            onChange={(e) => setNewKey(e.target.value)}
          />
          <DynamicInput
            type="text"
            placeholder="New value"
            value={newValue}
            onChange={(e) => setNewValue(e.target.value)}
          />
          <AddButton onClick={handleAdd}>Add</AddButton>
        </DynamicInputGroup>
      </DynamicSectionContainer>
    );
  };
  

// RightPanel component
const RightPanel: React.FC<RightPanelProps> = ({ selectedNode, updateNode }) => {
    const [isPanelVisible, setIsPanelVisible] = useState(true);
  
    useEffect(() => {
      if (selectedNode) {
        setIsPanelVisible(true);
      }
    }, [selectedNode]);
  
    const togglePanel = () => {
      setIsPanelVisible(!isPanelVisible);
    };
  
    if (!selectedNode) {
      return null;
    }
  
    const getProperties = (nodeType: string): PropertyFieldType[] => {
      switch (nodeType) {
        case 'start':
          return startNodeProperties;
        case 'llm':
          return llmNodeProperties;
        case 'tool':
          return toolNodeProperties;
        default:
          return [];
      }
    };
  
    const properties = getProperties(selectedNode.type || '');
  
    const handleChange = (propertyId: string, value: any) => {
      const updatedNode: Node<NodeData> = {
        ...selectedNode,
        data: {
          ...selectedNode.data,
          [propertyId]: value
        }
      };
      updateNode(updatedNode);
    };
  
    return (
      <>
        <IconWrapper isPanelVisible={isPanelVisible} onClick={togglePanel}>
          {isPanelVisible ? <ArrowRightCircle /> : <ArrowLeftCircle />}
        </IconWrapper>
        <PanelContainer isPanelVisible={isPanelVisible}>
          <PanelTitle>
            {selectedNode.type ? `${selectedNode.type.toUpperCase()} Node Properties` : 'Node Properties'}
          </PanelTitle>
          <PropertiesContainer>
            {properties.map((prop) => {
              if (prop.type === 'section' && selectedNode.type === 'start') {
                const startNodeData = selectedNode.data as StartNodeData;
                return (
                  <DynamicSection
                    key={prop.id}
                    sectionName={prop.label}
                    data={startNodeData[prop.id as 'config' | 'state'] || {}}
                    onUpdate={(newData) => handleChange(prop.id, newData)}
                  />
                );
              } else if (prop.type === 'select') {
                return (
                  <PropertyFieldWrapper key={prop.id}>
                    <Label htmlFor={prop.id}>{prop.label}:</Label>
                    <Select
                      id={prop.id}
                      value={selectedNode.data[prop.id] || ''}
                      onChange={(e) => handleChange(prop.id, e.target.value)}
                    >
                      {prop.options?.map((option: string) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Select>
                  </PropertyFieldWrapper>
                );
              } else {
                return (
                  <PropertyFieldWrapper key={prop.id}>
                    <Label htmlFor={prop.id}>{prop.label}:</Label>
                    <Input
                      type={prop.type}
                      id={prop.id}
                      value={selectedNode.data[prop.id] || ''}
                      onChange={(e) => handleChange(prop.id, e.target.value)}
                    />
                  </PropertyFieldWrapper>
                );
              }
            })}
          </PropertiesContainer>
        </PanelContainer>
      </>
    );
  };
  
  export default RightPanel;